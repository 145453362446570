

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import { batchAssociateGroup, getAppGroupList } from '@common-src/service3/app';
import { FormControlType } from '@common-src/model/form-control';

@Component
export default class SetGroupDialog extends FormDialogComponent<any> {
    appIdList: string = null;

    dialogOpen(appIdList): void {
        this.appIdList = appIdList;
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '批量设置分组';
        this.$nextTick(() => {
            if (this.jtlForm) {
                // this.jtlForm.initForm(importModel, viewMode);
                this.jtlForm.initFormByControls([{
                    key: 'groupId',
                    label: '分组',
                    type: FormControlType.SELECT,
                    optionsPromise: getAppGroupList,
                    mode: 'tag',
                    required: true
                }] as any);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在设置，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                const params = {
                    groupId: _.get(this.jtlForm, 'formModel.groupId'),
                    appIdList: this.appIdList
                };
                batchAssociateGroup(params).then(res => {
                    this.showMessageSuccess('批量设置成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
