































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AppGroupEntityModel, AppGroupQueryModel } from '@common-src/entity-model/app-entity';
import { AppAssociateGroupService, updateAppSequence } from '@common-src/service3/app';
import SetGroupDialog from './set-group-dialog.vue';
import DeployAppDialog from './deploy-app-dialog.vue';
import GroupComponent from '@common-src/mixins/group-component';

const appService = new AppAssociateGroupService();

@Component({
    components: {
        'set-group-dialog': SetGroupDialog
    }
})
export default class AppListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    AppGroupEntityModel = AppGroupEntityModel;
    selectedRowKeys = [];
    created() {
        this.initTable({
            service: appService,
            queryModel: new AppGroupQueryModel(),
            tableColumns: AppGroupEntityModel.getTableColumns()
        });
        this.getList();
    }

    onSelectChange(selectedRowKeys) {
        this.selectedRowKeys = selectedRowKeys;
    }

    batchDeploySetAppGroup() {
        if (_.isEmpty(this.selectedRowKeys)) {
            this.showMessage('请先选择需要分组的应用');
            return;
        }
        (this.$refs.setGroupDialog as SetGroupDialog).dialogOpen(this.selectedRowKeys);
    }
    changeList(groupIds) {
        console.log(groupIds);
        (this.queryModel as AppGroupQueryModel).groupId = _.isEmpty(groupIds) ? 'all' : groupIds[0];
        this.getList();
    }
    changeSequence(record) {
        const param = {
            id: record.id,
            appSequence: record.appSequence
        };
        if (record.appSequence === null) {
            this.getList();
            return;
        }
        updateAppSequence([param]).then(res => {
            this.getList();
        });
    }

    treeSelect(groupIds: Array<string>) {
        if (_.isEmpty(groupIds)) {
            return;
        }
        (this.queryModel as AppGroupQueryModel).groupId = _.isEmpty(groupIds) ? 'all' : groupIds[0];
    }
}

